import React, { useState, useRef } from "react";
import styles from "./styles.module.scss";
import { ReactComponent as AutoAcceptDisabledIcon } from "src/images/auto-accept-disabled.svg";
import { ReactComponent as AutoAcceptEnabledIcon } from "src/images/auto-accept-enabled.svg";
import useClickOutside from "src/hooks/useClickOutside";
import AutoAcceptDisabledPopup from "../AutoAcceptPopup";

const AutoAcceptDisabledButton = ({ autoAcceptDisabled, toggleAutoAccept }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const popupRef = useClickOutside(() => setIsPopupVisible(false));

  const handleButtonClick = () => {
    setIsPopupVisible(true);
  };

  const handleConfirm = async () => {
    try {
      await toggleAutoAccept(!autoAcceptDisabled);
      setIsPopupVisible(false);
    } catch (error) {
      console.error("Error toggling auto accept:", error);
    }
  };

  const handleCancel = () => {
    setIsPopupVisible(false);
  };

  return (
    <>
      <button
        onClick={handleButtonClick}
        className={styles.button}
      >
        {!autoAcceptDisabled ? (
          <AutoAcceptEnabledIcon />
        ) : (
          <AutoAcceptDisabledIcon />
        )}
      </button>
      <AutoAcceptDisabledPopup
        showPopup={isPopupVisible}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        autoAcceptDisabled={autoAcceptDisabled}
        ref={popupRef}
      />
    </>
  );
};

export default AutoAcceptDisabledButton;
